import Vue from 'vue'
import Router from 'vue-router'
import DashboardLayout from '@/layout/DashboardLayout'
import AuthLayout from '@/layout/AuthLayout'
import axios from 'axios'
import Store from './store'
Vue.use(Router)

const withoutAuthenticationPathList = [
  '/login'
]

const router = new Router({
  linkExactActiveClass: 'active',
  routes: [
    {
      path: '/',
      redirect: 'TablesUser',
      component: DashboardLayout,
      children: [
        {
          path: '/icons',
          name: 'icons',
          component: () => import(/* webpackChunkName: "demo" */ './views/Icons.vue')
        },
        {
          path: '/profile',
          name: 'profile',
          component: () => import(/* webpackChunkName: "demo" */ './views/UserProfile.vue')
        },
        {
          path: '/maps',
          name: 'maps',
          component: () => import(/* webpackChunkName: "demo" */ './views/Maps.vue')
        },
        {
          path: '/chat',
          name: 'chat',
          component: () => import(/* webpackChunkName: "demo" */ './views/Chat.vue')
        },
        {
          path: '/tables',
          name: 'Blog',
          component: () => import(/* webpackChunkName: "demo" */ './views/Tables.vue')
        },
        {
          path: '/TablesUser',
          name: 'Users',
          component: () => import(/* webpackChunkName: "demo" */ './views/TablesUser.vue')
        }
        ,
        {
          path: '/boost',
          name: 'boost',
          component: () => import(/* webpackChunkName: "demo" */ './views/boost.vue')
        },
        {
          path: '/LeagueOfLegends',
          name: 'League Of Legends',
          component: () => import(/* webpackChunkName: "demo" */ './views/leagueOfLegends.vue')
        },
        {
          path: '/tft',
          name: 'tft',
          component: () => import(/* webpackChunkName: "demo" */ './views/tft.vue')
        },
        {
          path: '/valo',
          name: 'valo',
          component: () => import(/* webpackChunkName: "demo" */ './views/valo.vue')
        },

        {
          path: '/contact',
          name: 'Contact',
          component: () => import(/* webpackChunkName: "demo" */ './views/Contact.vue')
        },
        {
          path: '/game',
          name: 'Games',
          component: () => import(/* webpackChunkName: "demo" */ './views/games.vue')
        },
        {
          path: '/request',
          name: 'Requests',
          component: () => import(/* webpackChunkName: "demo" */ './views/Requests.vue')
        },
        {
          path: '/order',
          name: 'Orders',
          component: () => import(/* webpackChunkName: "demo" */ './views/Order.vue')
        }

      ]
    },
    {
      path: '/',
      redirect: 'login',
      component: AuthLayout,
      children: [
        {
          path: '/login',
          name: 'login',
          component: () => import(/* webpackChunkName: "demo" */ './views/Login.vue')
        },
        // {
        //   path: '/register',
        //   name: 'register',
        //   component: () => import(/* webpackChunkName: "demo" */ './views/Register.vue')
        // }
      ]
    }
  ]

})
router.beforeEach(async (to, from, next) => {
  console.log('to ', to)
  console.log('from ', from)
  console.log(withoutAuthenticationPathList)
  if (localStorage.getItem('refreshToken')) {
    if (localStorage.getItem('UId') && !Store.getters.getUserInfo._id) {
      axios.post('https://eloboost-tn.herokuapp.com/api/user/getUser', { user: localStorage.getItem('UId') })
        .then((response) => {
          console.log(response)
          Store.commit('setUserInfo', response.data.user)
          console.log(Store.getters.getUserInfo._id)
        })
    }
    if (!withoutAuthenticationPathList.includes(to.path)) {
      return next()
    } else {
      await router.push({
        path: from.path
      })
    }
  } else {
    if (!withoutAuthenticationPathList.includes(to.path)) {
      await router.push({
        path: '/login'
      })
    } else {
      return next()
    }
  }
  if (withoutAuthenticationPathList.includes(to.path)) {
  }
  return next()

})
export default router;
