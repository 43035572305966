var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper",class:{ 'nav-open': _vm.$sidebar.showSidebar }},[_c('side-bar',{attrs:{"background-color":_vm.sidebarBackground,"short-title":"Argon","title":"Argon"}},[_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{
          name: 'Offres management',
          icon: 'ni ni-controller',
          path: '/boost',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Order management (' + _vm.counter.orders + ')',
          icon: 'ni ni-bullet-list-67',
          path: '/order',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'User management (' + _vm.counter.users + ')',
          icon: 'ni ni-single-02 ',
          path: '/TablesUser',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Requests management (' + _vm.counter.requests + ')',
          icon: 'ni ni-badge',
          path: '/request',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Blog management',
          icon: 'ni ni-bullet-list-67',
          path: '/tables',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Games',
          icon: 'ni ni-app',
          path: '/game',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Contact (' + _vm.counter.contact + ')',
          icon: 'ni ni-email-83',
          path: '/contact',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Gestion du compte',
          icon: 'ni ni-single-02 ',
          path: '/profile',
        }}})],1)],2),_c('div',{staticClass:"main-content",attrs:{"data":_vm.sidebarBackground}},[_c('dashboard-navbar'),_c('div',{on:{"click":_vm.toggleSidebar}},[_c('fade-transition',{attrs:{"duration":200,"origin":"center top","mode":"out-in"}},[_c('router-view')],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }