<template>
  <div id="app">
    <notifications></notifications>
    <router-view />
  </div>
</template>
<script>
import axios from "axios";

export default {
  async created() {
    if (localStorage.getItem("UId")) {
      await axios
        .post("https://eloboost-tn.herokuapp.com/api/user/getUser", {
          user: localStorage.getItem("UId"),
        })
        .then((response) => {
          console.log(response);
          this.$store.commit("setUserInfo", response.data.user);
          console.log(this.$store.getters.getUserInfo._id);
        });
    }
  },
};
</script>
