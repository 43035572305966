"use strict";

const state = {
  activeUser: {},
  userInfo:{}
};

const getters = {
  getUserInfo: (state) => {
    return state.userInfo;
  },
  getActiveUser: (state) => {
    return state.activeUser;
  }
};

const mutations = {
  setUserInfo: (state, payload) => {
    state.userInfo = payload;
  },
  setActiveUser: (state, payload) => {
    state.activeUser = payload;
  }
};

const actions = {
  setUserInfo: ({ commit }, payload) => {
    commit("setUserInfo", payload);
  },
  setActiveUser: ({ commit }, payload) => {
    commit("setActiveUser", payload);
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
};
