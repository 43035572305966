<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <side-bar
      :background-color="sidebarBackground"
      short-title="Argon"
      title="Argon"
    >
      <template slot="links">
        <!-- <sidebar-item
          :link="{
            name: 'Dashboard',
            icon: 'ni ni-tv-2',
            path: '/dashboard',
          }"
        /> -->

        <sidebar-item
          :link="{
            name: 'Offres management',
            icon: 'ni ni-controller',
            path: '/boost',
          }"
        />
        <sidebar-item
          :link="{
            name: 'Order management (' + counter.orders + ')',
            icon: 'ni ni-bullet-list-67',
            path: '/order',
          }"
        />
        <sidebar-item
          :link="{
            name: 'User management (' + counter.users + ')',
            icon: 'ni ni-single-02 ',
            path: '/TablesUser',
          }"
        />
        <sidebar-item
          :link="{
            name: 'Requests management (' + counter.requests + ')',
            icon: 'ni ni-badge',
            path: '/request',
          }"
        />

        <sidebar-item
          :link="{
            name: 'Blog management',
            icon: 'ni ni-bullet-list-67',
            path: '/tables',
          }"
        />
        <sidebar-item
          :link="{
            name: 'Games',
            icon: 'ni ni-app',
            path: '/game',
          }"
        />
        <sidebar-item
          :link="{
            name: 'Contact (' + counter.contact + ')',
            icon: 'ni ni-email-83',
            path: '/contact',
          }"
        />
        <sidebar-item
          :link="{
            name: 'Gestion du compte',
            icon: 'ni ni-single-02 ',
            path: '/profile',
          }"
        />
        <!--   <sidebar-item
          :link="{
            name: 'Dashboard',
            icon: 'ni ni-tv-2 text-primary',
            path: '/dashboard'
          }"
        />

     <sidebar-item :link="{name: 'Icons', icon: 'ni ni-planet text-blue', path: '/icons'}"/>
        <sidebar-item :link="{name: 'Maps', icon: 'ni ni-pin-3 text-orange', path: '/maps'}"/>
        <sidebar-item :link="{name: 'User Profile', icon: 'ni ni-single-02 text-yellow', path: '/profile'}"/>
        <sidebar-item :link="{name: 'Tables', icon: 'ni ni-bullet-list-67 text-red', path: '/tables'}"/>
        <sidebar-item :link="{name: 'Login', icon: 'ni ni-key-25 text-info', path: '/login'}"/>
        <sidebar-item :link="{name: 'Register', icon: 'ni ni-circle-08 text-pink', path: '/register'}"/> -->
      </template>
    </side-bar>
    <div class="main-content" :data="sidebarBackground">
      <dashboard-navbar></dashboard-navbar>

      <div @click="toggleSidebar">
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </fade-transition>
        <content-footer v-if="!$route.meta.hideFooter"></content-footer>
      </div>
    </div>
  </div>
</template>
<script>
import DashboardNavbar from "./DashboardNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import { FadeTransition } from "vue2-transitions";
import axios from "axios";

export default {
  components: {
    DashboardNavbar,
    ContentFooter,
    FadeTransition,
  },
  data() {
    return {
      sidebarBackground: "vue", //vue|blue|orange|green|red|primary
      counter: {
        orders: 0,
        users: 0,
        requests: 0,
        contact: 0,
      },
    };
  },
  created() {
    axios
      .get("https://eloboost-tn.herokuapp.com/api/user/totals")
      .then((response) => {
        this.counter = response.data;
      });
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
  },
};
</script>
<style>
.navbar-vertical .navbar-nav .nav-link {
  font-size: 1.2em;
}
</style>
