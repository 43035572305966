<template>
  <footer class="footer px-4 ">
    <!-- footer-position -->
    <!-- <div class="row align-items-center justify-content-lg-between">
      <div class="col-lg-6">
        <div class="copyright text-center text-lg-left text-muted">
          © {{ year }}
          <a
            href="#"
            class="font-weight-bold ml-1"
            target="_blank"
            >A.S</a
          >
          &
          <a
            href="https://www.omarmhiri.com/"
            class="font-weight-bold ml-1"
            target="_blank"
            >O.M</a
          >
        </div>
      </div>
      <div class="col-lg-6">
        <ul
          class="nav nav-footer justify-content-center justify-content-lg-end"
        >
          <li class="nav-item">
            <router-link to="/" class="nav-link" target="_blank"
              >Elo Boost</router-link
            >
          </li>
        </ul>
      </div>
    </div> -->
  </footer>
</template>
<script>
export default {
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
};
</script>
<style>
.footer-position{
  position: absolute!important;
  width: 100%!important;
  bottom: 0!important;
}
</style>
